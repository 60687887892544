<script>
import Layout from "../layouts/horizontal";



export default {
  components: {
    Layout
  },
  data() {
    
    return {
      isRecording: false,
      transcript: ''
    };
  },
  created() {
    
  },
  watch: {
   
  },
  computed: {
   
  },
  methods: {
    startRecording() {
      this.isRecording = true;
      this.transcript = '';

      console.log('window.SpeechRecognition', window.SpeechRecognition);
      console.log('window.webkitSpeechRecognition', window.webkitSpeechRecognition);
      console.log('window.msSpeechRecognition', window.msSpeechRecognition);

      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition || window.msSpeechRecognition;
      const speechRecognizer = new SpeechRecognition();
      speechRecognizer.lang = 'en-US'; 
      speechRecognizer.continuous = true; // Установите параметр continuous в true
      speechRecognizer.start();

      speechRecognizer.onstart = () => {
        console.log('Speech recognition started');
      };

      speechRecognizer.onerror  = event => {
        console.error('Speech recognition error:', event.error);
        this.stopRecording();
      };
      speechRecognizer.onresult = event => {
        console.log('speechRecognizer.onresult', event);
        const resultIndex = event.resultIndex;
        const transcript = event.results[resultIndex][0].transcript;
        this.transcript = transcript;
        this.stopRecording();
      };
    },
    stopRecording() {
      this.isRecording = false;
    }
  }
};
</script>

<template>
  <Layout>
   
    <h1>Voice to text</h1>
   
    <textarea class="form-control mb-4" v-model="transcript" rows="4" cols="50" readonly></textarea>
    <button class="btn btn-primary mr-2" @click="startRecording" :disabled="isRecording">Record</button>
    <button class="btn btn-secondary" @click="stopRecording" :disabled="!isRecording">Stop</button>
  </Layout>
</template>